import { MessageBus } from '../../commands/framework/MessageBus';
import { ToolbarStateChangedEvent } from '../../commands/toolbar/SetToolbarStateCommand';
import { Toolbar } from './Toolbar';
import { logger } from '@xspecs/logger';
import { ResolvedThreadVisibilityToggledEvent } from '../../commands/comments/ToggleResolvedThreadsVisibilityCommand';
import { SchemeName, SchemeSetEvent } from '../../commands/apps/SetSchemeCommand';
import { FileLoadedEvent } from '../../commands/spaces/LoadFileCommand';
import { DevModeToggleEvent } from '../../commands/ide/ToggleDevModeCommand';

export class ToolbarProjection {
  constructor(
    private readonly messageBus: MessageBus,
    private readonly toolbar: Toolbar,
    private readonly owner: string,
  ) {
    messageBus.subscribe([ToolbarStateChangedEvent], this.onToolbarStateChangedEvent.bind(this), this.owner);
    this.messageBus.subscribe(
      [ResolvedThreadVisibilityToggledEvent],
      this.onResolvedThreadVisibilityToggledEvent.bind(this),
      this.owner,
    );
    this.messageBus.subscribe([SchemeSetEvent], this.onSchemeSetEvent.bind(this), this.owner);
    this.messageBus.subscribe([DevModeToggleEvent], this.onToggleDevModeEvent.bind(this), this.owner);
    this.messageBus.subscribe([FileLoadedEvent], this.onFileLoadedEvent.bind(this), this.owner);
  }

  private onToolbarStateChangedEvent(event: ToolbarStateChangedEvent) {
    switch (event.params.key) {
      case 'compactMode':
        this.toolbar.setCompactMode(event.params.value as boolean);
        break;
      case 'reset':
        this.toolbar.reset();
        break;
      case 'search':
        this.toolbar.search(event.params.value as string);
        break;
      case 'scheme':
        this.toolbar.changeScheme(event.params.value as string);
        break;
      case 'selectedEntity':
        this.toolbar.selectEntity(event.params.value as string);
        break;
      case 'typeFilter':
        this.toolbar.setFilter(event.params.value as string);
        break;
      case 'close':
        this.toolbar.close();
        break;
      default:
        logger.warn(`Invalid key projecting : ${event.params.key}`);
    }
  }

  private onResolvedThreadVisibilityToggledEvent(event: ResolvedThreadVisibilityToggledEvent) {
    this.toolbar.showResolvedComments(event.params.value);
  }

  private onSchemeSetEvent(event: SchemeSetEvent) {
    this.toolbar.setDefaultSelectedConstruct(event.params.scheme);
  }

  private onToggleDevModeEvent(event: DevModeToggleEvent) {
    //console.log('active scheme', ModelContext.getInstance().getActiveScheme());
  }

  private onFileLoadedEvent(event: FileLoadedEvent) {
    // TODO: this is a workaround, the SchemeSetEvent is not coming though in the projection for the initial load
    const schemes: Record<string, SchemeName> = {
      ndd: SchemeName.Narrative,
      em: SchemeName.EventModelling,
    };
    const schema = schemes[event.params.fileExtension || ''];
    if (schema) this.toolbar.setDefaultSelectedConstruct(schema);
  }
}
